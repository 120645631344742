import { Show, createResource } from 'solid-js'
import { useParams } from '@solidjs/router'
import SeriesEditForm from '~/components/forms/series-edit/series-edit'
import Header from '~/components/header/header'
import Loading from '~/components/loading/loading'
import { getSeries } from '~/services/database/series'
import { SeriesParams } from '~/types/routes/series/entry'

export default function EditSeries() {
  const { id } = useParams<SeriesParams>()
  const [series] = createResource(id, getSeries)
  return (
    <>
      <Header />
      <Show
        when={series.latest}
        fallback={<Loading />}
        keyed
      >
        {(series) => (
          <SeriesEditForm series={series} />
        )}
      </Show>
    </>
  )
}
