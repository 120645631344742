import { InferOutput, array, nonEmpty, object, pipe, string } from 'valibot'
import { SeriesWithPermissions } from '~/types/database/series'

export interface SeriesEditFormProps{
  series: SeriesWithPermissions
}

export const SeriesEditSchema = object({
  client: pipe(string(), nonEmpty()),
  title: pipe(string(), nonEmpty()),
  author: string(),
  translators: array(string()),
  proofreaders: array(string()),
  typesetters: array(string()),
})

export type SeriesEditFormType = InferOutput<typeof SeriesEditSchema>