import { Component, createResource } from 'solid-js'
import styles from '~/components/forms/styles/complex-form.module.scss'
import { SeriesEditFormProps, SeriesEditFormType, SeriesEditSchema } from '~/components/forms/series-edit/series-edit.interfaces'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { useNavigate } from '@solidjs/router'
import { updateSeries } from '~/services/database/series'
import { Permission } from '~/types/database/series'
import { SelectEntry } from '~/components/fields/multi-select/multi-select.interfaces'
import { getContacts } from '~/services/database/contacts'
import MultiSelectField from '~/components/fields/multi-select/multi-select'
import { getUserIdsForPermissionType } from '~/helpers/database-conversions'
import { getCollaborators } from '~/services/database/collaborators'
import { translations } from '~/translations'

const SeriesEditForm: Component<SeriesEditFormProps> = ({ series }) => {
  const navigate = useNavigate()
  const [form, { Form, Field }] = createForm<SeriesEditFormType>({
    initialValues: {
      title: series.title,
      client: series.client,
      author: series.author ?? '',
      translators: getUserIdsForPermissionType(series.series_permissions, 'translation'),
      proofreaders: getUserIdsForPermissionType(series.series_permissions, 'proofreading'),
      typesetters: getUserIdsForPermissionType(series.series_permissions, 'typesetting')
    },
    validate: valiForm(SeriesEditSchema),
  })

  const onSubmit: SubmitHandler<SeriesEditFormType> = async (values) => {
    const permissions: Permission[] = []
    const translatorsPermissions: Permission[] = values.translators.map(entry => ({ userId: entry, permission: 'translation' }))
    const proofreadersPermissions: Permission[] = values.proofreaders.map(entry => ({ userId: entry, permission: 'proofreading' }))
    const typesettersPermissions: Permission[] = values.typesetters.map(entry => ({ userId: entry, permission: 'typesetting' }))
    permissions.push(...translatorsPermissions, ...proofreadersPermissions, ...typesettersPermissions)

    await updateSeries({
      id: series.id,
      ...values,
      permissions
    }).then((result) => {
      if (result) navigate(`/series/${result.id}`)
    })
  }

  const [availableOptions] = createResource(async () => {
    const contacts = await getContacts()
    const contactAsSelectEntries: SelectEntry[] = contacts.map(entry => ({
      label: entry.meta?.name ?? entry.meta?.email ?? entry.contact_id,
      value: entry.contact_id
    }))
    const collaborators = await getCollaborators(series.id)()
    const collaboratorsAsSelectEntries: SelectEntry[] = collaborators.map(entry => ({
      label: entry.name ?? entry.email ?? entry.userId,
      value: entry.userId
    }))
    const options: SelectEntry[] = [
      ...contactAsSelectEntries,
      ...collaboratorsAsSelectEntries
    ]
    return options
  }, {
    initialValue: []
  })

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          {translations().series.forms.update.title}
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="client">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.update.client}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="title">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.update.seriesTitle}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="author">
              {(field, props) => (
                <TextField
                  label={translations().series.forms.update.author}
                  {...field}
                  {...props}
                />
              )}
            </Field>

            <Button
              type='submit'
              style='lagoon'
              isLoading={form.submitting}
            >
              {translations().general.actions.edit}
            </Button>
          </div>
        </div>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name="translators" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.translation}
                  options={availableOptions.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="proofreaders" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.proofreading}
                  options={availableOptions.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
            <Field name="typesetters" type='string[]'>
              {(field, props) => (
                <MultiSelectField
                  label={translations().editor.steps.typesetting}
                  options={availableOptions.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SeriesEditForm